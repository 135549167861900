<template>
  <div
    v-if="content.content"
    class="module__video-modal order-last mt-2 flex w-12/12 px-gap sm:ml-1/12 sm:w-7/12 lg:mt-auto lg:w-5/12"
    :class="reversed ? 'lg:ml-0' : 'lg:ml-7/12'"
  >
    <div class="wrapper__img">
      <ImagesPicture
        v-if="content.image.fields"
        :image="content.image.fields"
        classes="w-full"
      />
    </div>
    <div class="ml-1">
      <RichTextRenderer
        v-if="content.content"
        :document="content.content"
        :node-renderers="nodeRenderers"
      />
      <span
        class="link--watch cursor-pointer text-base font-medium text-blue"
        tabindex="0"
        title="open modal"
        @click="openVideoModal(content, 'Modal Video')"
        @keydown="openVideoModal(content, 'Modal Video')"
        >{{ content.label
        }}<svg
          v-if="content.label"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="inline"
        >
          <circle cx="12.5" cy="12.5" r="12" stroke="#0384FB" />
          <path
            d="M17 12.5L10.25 16.3971L10.25 8.60289L17 12.5Z"
            fill="#0384FB"
          />
        </svg>
      </span>
    </div>
    <ModalsYoutubeEmbedModal
      v-if="content.mobileImage"
      :is-open="isOpen"
      :close-location="'outer'"
      :video="{ id: ytId }"
      :youtube-player-state="ytPlayerState"
      @closeVideoModal="closeModal"
    />
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      content: {
        type: Object,
        required: true,
      },
      reversed: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        isOpen: false,
        ytId: null,
        ytPlayerState: 'unstarted',
      };
    },
    methods: {
      openVideoModal(url, type) {
        if (type === 'Modal Video') {
          let id =
            url.mobileImage && url.mobileImage.fields.id
              ? url.mobileImage.fields.id
              : '';
          id = id.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
          this.ytId =
            id[2] !== undefined ? id[2].split(/[^0-9a-z_\-]/i)[0] : id[0];
          this.ytPlayerState = 'play';
          this.isOpen = true;
        }
      },
      closeModal() {
        this.isOpen = false;
        this.ytPlayerState = 'unstarted';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .module__video-modal {
    .wrapper__img {
      @apply flex-shrink-0 rounded-full;

      width: 65px;

      @screen md {
        width: 78px;
      }
    }
    :deep(img) {
      @apply rounded-full;
    }
    :deep(p) {
      @apply mb-1/2 font-medium text-blue-dark;
    }
    .link--watch {
      &:hover {
        svg {
          circle {
            fill: theme('colors.blue.DEFAULT');
          }
          path {
            fill: white;
          }
        }
      }
      svg {
        margin-left: 8px;
      }
    }
  }
  .modal {
    :deep(.yt-embed-inner),
    :deep(.yt-embed-container) {
      @apply h-full pb-0;
    }

    :deep(.youtubeIframe) {
      @apply relative;
    }
  }
</style>
